import React, { Component } from "react";
import { MDBContainer,  MDBInput, MDBBtn, MDBModal, MDBModalHeader, MDBModalBody, MDBIcon } from "mdbreact";
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'bootstrap-css-only/css/bootstrap.min.css';
import 'mdbreact/dist/css/mdb.css';
import * as formConfig from '../data/formConfig';
import axio from 'axios';


const dataHolder=[];
 
class MDBForm extends Component {
  state = {
    modal: false,
    data:dataHolder
  };
 showState=()=>{
  alert(this.state.data.join(' '));
}

newdata=(idx,val)=>{
  let data=[...this.state.data];
  data[idx]=val;
  return data;
}
showError=()=>{
  alert('Missing Submit Function');
}
 showit=(idx,e)=>{
 
      this.setState({
        ...this.state,
        data:this.newdata(idx,e.target.value)
      });
 }
  toggle = () => {
    this.setState({
      ...this.state,
      modal: !this.state.modal
    });
  };

  submitIt=(data)=>{
    this.toggle();
    if(this.props.submitFunc ==null)
    this.showError();
    else
    this.props.submitFunc(data);
  }  
  logValue = value => {
    console.log(value);
  };
  
  resetState=()=>{
    let newdata=[...this.state.data];
    for(var i=0;i<newdata.length;i++)newdata[i]='';
    this.setState({
      ...this.state,
      data:newdata
    })
  }

 funcs=[this.showState,this.resetState];
debugbt= this.props.debug? 
              <MDBBtn color="info" className="mb-2" onClick={this.funcs[0]}>
              Show value
              <MDBIcon icon="paper-plane" className="ml-1" />
              </MDBBtn >
   :null
;
 
  render() {
    return (
      <MDBContainer>
        <MDBBtn onClick={this.toggle} className="mx-auto mbr-section-btn" id='micsForm' style={this.props.style}>
         {this.props.formTitle}
        </MDBBtn>
        <MDBModal
          isOpen={this.state.modal}
          toggle={this.toggle}
          size="lg"
          cascading
        >
          <MDBModalHeader
            toggle={this.toggle}
            titleClass="d-inline title"
            className="text-center light-blue darken-3 white-text"
          >
            <MDBIcon icon="pencil-alt" />
            {this.props.formTitle}
          </MDBModalHeader>
          <MDBModalBody>
          
          {formConfig.formData.map((v,i)=>
          <MDBInput label={v.label} type={v.type} iconClass={v.iconClass} icon={v.icon} rows={v.rows} value={this.state.data[i]} onChange={this.showit.bind(this,i)} />
          )}

            <div className="text-center mt-1-half">
            
              <MDBBtn
                color="info"
                className="mb-2"
                onClick={this.submitIt.bind(this,[...this.state.data,'cansecs'])}
              >
                send
              <MDBIcon icon="paper-plane" className="ml-1" />
              </MDBBtn>
              {this.debugbt}
                  <MDBBtn color="info" className="mb-2" onClick={this.funcs[1]}>
              Reset
              <MDBIcon far icon="edit" className="ml-1" />
              </MDBBtn >
            </div>
          
          </MDBModalBody>
        </MDBModal>
      </MDBContainer>
    );
  }
}

export default MDBForm;
