import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Nav from 'react-bootstrap/Nav';

const MicsDropdown =props =>{
  let dd=props.ddl.map((l)=>{
    return   (l.title==='--')?<NavDropdown.Divider/>: 
  <NavDropdown.Item onClick={l.action}>{l.title}</NavDropdown.Item>;});

  return (

      <NavDropdown title="Dropdown" id="collasible-nav-dropdown">
      {dd}
      </NavDropdown>
);
}
export default MicsDropdown;