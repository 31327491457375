import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import MicsDropdown from './MicsDropdown'

const MicsNavLink =props =>{
    let ll=props.linkList.map((l)=>{
    return   <Nav.Link href={l.href} onClick={l.action}>{l.title} </Nav.Link>;
    });
  return (
   <Nav className={props.class}>
    {ll}
    {props.dropDown}
    </Nav>
    

);
}
export default MicsNavLink;