import React from "react";
import {Button} from "react";
const func=()=>{
  alert('ok');
}

const FiguresPage = (props) => {
  
  return (
    props.figures.map((f)=>
 
    <figure className={f.class}>
       
      <img src={f.src} onClick={f.clickFunc} 
        alt="" style={f.style} onClick={f.clickFunc} className={f.imgClass}/>
      
      <figcaption className="figure-caption" onClick={f.clickFunc}>
      {f.caption}
      </figcaption>
    </figure>
    
    )
  );
}

export default FiguresPage;