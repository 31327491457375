//config data for the form
import React from 'react';
import {MDBBtn,MDBIcon} from 'mdbreact';
import 'mdbreact/dist/css/mdb.css';
const formData=[
  ,{"label":"Name","type":"text","iconClass":"dark-grey"}
  ,{"label":"email","type":"email","iconClass":"dark-grey"}
  ,{"label":"phone","type":"number","iconClass":"dark-grey"}
  ,{"label":"Message","type":"textarea","iconClass":"dark-grey","icon":"pencil-alt","rows":"5"}

  ];
const formTitle="Contact US";
const formSubmitFunc=(data)=>{
    window.formSubmit(data);
}
const noUpperCase={textTransform:'unset',fontWeight:'bold'};
const formButton=()=>{ return(<MDBBtn
                color="info"
                className="mb-2"
              >
                send
              <MDBIcon icon="paper-plane" className="ml-1" />
              </MDBBtn>);
};
export {formData,formSubmitFunc,formTitle,noUpperCase,formButton}