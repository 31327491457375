import React,{useState} from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import * as formConfig from './formConfig';
import MDBForm from '../components/MDBForm';
import MicsModal from '../components/MicsModal';
const aboutUS=<div className='container text-center'><h3>Mic's App &copy; {new Date().getFullYear()}</h3></div>;
      
      
const check=()=>{
      alert('check');
    };

const  t1="Mic'sApp";
const ddl=[
    {id:1,href:'#d1',title:'fun',action:check},
    {id:2,href:'#d2',title:'action',action:check},
    {id:3,"title":"--"},
    {id:4,title:t1,href:'#d3',action:check},
    {id:5,title:"item5",action:check}
    ];
    
 const    homeLink="https://www.micsapp.com";
 const    homeTitle="Mic'sApp";
 const    bg='light';
 const    variant='light';
 const    logo='http://eshopqa.micsapp.com/pub/media/logo/websites/1/Micsapp_3_1.png';
    
 const    linkList=[
      
      {href:'https://git.micsapp.com',title:"Join US"}
      ];

    
 const    linkList2=[
     //  {href:'http://www.micsapp.com',title:'mic'},
       {action:check,title:"About US"}
      ];
      //test only , in production need to fetch config file from server
 const    data=[
     {"class":"d-block w-100 fsbg ","src":"http://micstec.com/mapp/micstec/images/img1.jpg","alt":"1",
     "caption":`<div class='container' style='padding:20px;border-radius: 25px;border:solid 1px;max-width:800px;min-height:250px;
     background: rgba(76,94,139, 0.5);color:white;'><div style='margin-top:2em'>
     <h1 class='mbr-section-title mbr-bold pb-3 mbr-fonts-style display-5' style='font-size:45px' >Mic'sApp</h1>
     <span class='mbr-text pb-3 mbr-fonts-style display-5'>
     
     <p>IT solution provider</p><p>Affordable Price</p><p>
     Making life easier</p></span>
     </div><a class='btn btn-primary' href='#products'>Products</a></div>`}
  
    ];
    //this is test data
     const    data2=[
     {"class":"d-block w-100 fsbg ","src":"http://micstec.com/mapp/micstec/images/img1.jpg","alt":"1",
     "caption":"<div class='container' style=' border-radius: 25px;border:solid 2px;max-width:800px;min-height:300px; background: rgba(76,94,139, 0.5);'><div style='margin-top:3em' class=' mbr-section-btn'><span style='color:white;font-weight:5000;'> <h3 >Welcom to Mic'sApp</h3><p style='font-weight:300'>IT solution provider</p><p>Affordable Price</p><p> Make life easier</p></span></div><a class='mbr-section-btn btn btn-primary' href='#products'>See Products</a></div>"}
     ,{"class":"d-block w-100 fsbg ","src":"http://micstec.com/mapp/micstec/images/img2.jpg","alt":"2",
     "caption":"<span style='color:red'><h3>Second slide label</h3><p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p></span><a class='btn btn-primary btn-rounded' href='#products'>See Products</a>"}
     ,{"class":"d-block w-100 fsbg ","src":"http://micstec.com/mapp/micstec/images/img3.jpg","alt":"3",
     "caption":"<span style='color:red'><h3>Third slide label</h3><p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p></span><a class='btn btn-primary btn-rounded' href='#products'>See Products</a>"}
     ];
     const figureDetail=[
       {"detail":`<div class='container text-left' ><h1>Eshop</h1><hr>
       <p> Eshop is built with most popular E-commercial Platform and Leveraging latest Information Technology</p>
       <p> we help small bussiness to setup shopping site in fast speed with minimum cost</p>
       <p> Fully customized fit any need for any customer</p>
       <a class='btn btn-info' href="https://eshop.micsapp.com">Eshop Site Example</a>
       <a class='btn btn-primary' data-fancybox-close onClick='clickForm();'>Contact for Quote</a>
       </div>`}
      ,{"detail":`
      <h1>Website Builder</h1><hr>
       <p> We build morden website Leveraging latest Information Technology</p>
       <p> We help small bussiness to setup website in fast speed with minimum cost</p>
       <p> Fully customized, fit any need for any customer</p>
       <a class='btn btn-primary' data-fancybox-close onClick='clickForm();'>Contact for Quote</a>
      `}
      ,{"detail":`
           <h1>IT solution </h1><hr>
       <p> We provide IT solution using latest Information Technology</p>
       <p> We help small bussiness to setup fulfill their need for bussiness chanllenge</p>
       <p> support both on-Prem and on-cloud</p>
       <p> We had successfully help lots of small bussiness setup their Inforstructure in no time </p>
       <a class='btn btn-primary' data-fancybox-close onClick='clickForm();'>Contact for Quote</a>
      `}
        ,{"detail":`
           <h1>Mobile Apps </h1><hr>
       <p> We develop Mobile Apps for both Android and IOS</p>
       <p> help small bussiness to use Mobile App to get their customer connected</p>
       <p> enable bussiness owner sale their product and service more efficient</p>
       <p> help them stay close with latest technologies </p>
       <a class='btn btn-primary' data-fancybox-close onClick='clickForm();'>Contact for Quote</a>
      `}
     
      ]
     const custDetail=[
       {"url":"http://sushidate.ca"}
       ,{"url":"https://weteamania.com"}
       ,{"url":"http://empirewokchinesetakeout.com"}
       ,{"url":"http://5stardriving.ca"}
       ,{"url":"https://camp.conco.ca"}
       ,{"url":"http://teatalkteas.com"}
       ,{"url":"http://sunshine11354.com"}
       ,{"url":"http://toptaxcpa.net"}
       ,{"url":"http://fgoa.micsapp.com"}
       ]
 const clickFunc=(opt)=>{
  
   window.myalert(figureDetail[opt].detail);
  
  }
  const custFunc=(opt)=>{
   window.location.replace(custDetail[opt].url);
  }
 const anchor= 
  <div>
   <a name="products"></a>
   <h3> Mic'sApp Products </h3><br/>
   </div>
  ;
 const figures=[
     {"class":"figure col-3 col-md-2 col-lg-1","caption":"Eshop","imgClass":"figure-img img-fluid z-depth-1 rounded","style":{width:'65px',height:'65px'},"clickFunc":clickFunc.bind(this,0),"src":"/mapp/images/icons/micsapp_shopping.jpg"}
     ,{"class":"figure col-3 col-md-2 col-lg-1","caption":"Website ","imgClass":"figure-img img-fluid z-depth-1 rounded","style":{width:'65px',height:'65px'},"clickFunc":clickFunc.bind(this,1),"src":"/mapp/images/icons/micsapp_share.png"}
     ,{"class":"figure col-3 col-md-2 col-lg-1","caption":"Consulting","imgClass":"figure-img img-fluid z-depth-1 rounded","style":{width:'65px',height:'65px'},"clickFunc":clickFunc.bind(this,2),"src":"/mapp/images/icons/micsapp_support2.jpg"}
     ,{"class":"figure col-3 col-md-2 col-lg-1","caption":"Apps","imgClass":"figure-img img-fluid z-depth-1 rounded","style":{width:'65px',height:'65px'},"clickFunc":clickFunc.bind(this,3),"src":"/mapp/images/icons/micsapp_more.png"}

     ];
 const customers=[
     {"class":"figure col-3 col-md-2 col-lg-1","caption":"Sushidate","imgClass":"figure-img img-fluid z-depth-1 rounded","style":{width:'65px',height:'65px'},"clickFunc":custFunc.bind(this,0),"src":"/mapp/images/icons/sushidate_logo.png"}
     ,{"class":"figure col-3 col-md-2 col-lg-1","caption":"Teamania ","imgClass":"figure-img img-fluid z-depth-1 rounded","style":{width:'65px',height:'65px'},"clickFunc":custFunc.bind(this,1),"src":"/mapp/images/icons/teamania_logo.jpg"}
     ,{"class":"figure col-3 col-md-2 col-lg-1","caption":"Empirewok","imgClass":"figure-img img-fluid z-depth-1 rounded","style":{width:'65px',height:'65px'},"clickFunc":custFunc.bind(this,2),"src":"/mapp/images/icons/empirewok_logo.png"}
     ,{"class":"figure col-3 col-md-2 col-lg-1","caption":"5stardriving","imgClass":"figure-img img-fluid z-depth-1 rounded","style":{width:'65px',height:'65px'},"clickFunc":custFunc.bind(this,3),"src":"/mapp/images/icons/5stardriving_logo.png"}
     ,{"class":"figure col-3 col-md-2 col-lg-1","caption":"ChinaCamp","imgClass":"figure-img img-fluid z-depth-1 rounded","style":{width:'65px',height:'65px'},"clickFunc":custFunc.bind(this,4),"src":"/mapp/images/icons/camp_logo.PNG"}
     ,{"class":"figure col-3 col-md-2 col-lg-1","caption":"TeatalkTeas","imgClass":"figure-img img-fluid z-depth-1 rounded","style":{width:'65px',height:'65px'},"clickFunc":custFunc.bind(this,5),"src":"/mapp/images/icons/teatalkteas_logo.png"}
     ,{"class":"figure col-3 col-md-2 col-lg-1","caption":"Sunshine Wellness","imgClass":"figure-img img-fluid z-depth-1 rounded","style":{width:'65px',height:'65px'},"clickFunc":custFunc.bind(this,6),"src":"http://sunshine11354.com/sites/default/files/sunshine11354.80x80.jpg"}
     ,{"class":"figure col-3 col-md-2 col-lg-1","caption":"Toptaxcpa ","imgClass":"figure-img img-fluid z-depth-1 rounded","style":{width:'65px',height:'65px'},"clickFunc":custFunc.bind(this,7),"src":"https://www.toptaxcpa.net/toptaxcpa/assets/images/toptaxcpa-wb-98x97.png"}
     ,{"class":"figure col-3 col-md-2 col-lg-1","caption":"Fgoa","imgClass":"figure-img img-fluid z-depth-1 rounded","style":{width:'65px',height:'65px'},"clickFunc":custFunc.bind(this,8),"src":"https://fgoa.micsapp.com/sites/default/files/fGOaa_logo_1.PNG"}

     ];
  
 const footerLinkList=[
  {"href":"https://www.cansecs.com","action":null,"title":`Cansecs Mobile Apps`}
  ,{"href":"http://micstec.com","action":null,"title":"MicsTec POS Apps"}
  ,{"href":"mailto:mic@micsapp.com","action":null,"title":"Email:mic@micsapp.com"}
  ]
  const footerHeader=<p>Useful Resource</p>
export {check,t1,ddl,homeLink,homeTitle,bg,variant,logo,linkList,linkList2,data,clickFunc,figures,anchor,aboutUS,footerLinkList,footerHeader,customers}
