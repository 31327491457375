import React ,{Component} from 'react';
import './App.css';
import MicsNav from './components/MicsNav';
import MicsCarousel from './components/MicsCarousel';
import MicsFigure from './components/MicsFigure';
import MDBForm from './components/MDBForm';
import * as config from './data/config.micsapp';
import * as formConfig from './data/formConfig';
import MicsModal from './components/MicsModal';
import MicsFooter from './components/MicsFooter';
class App extends Component {

  
  componentDidMount(){
     
  }
  
  componentWillMount(){
  /* example of fetch config from server side
    const API = '/mapp/data/data1.json';
    fetch(API)
      .then(response => response.json())
      .then(data => {this.setState({ data1: data })});
  */
   }
       
 constructor(props) {
  super(props);
  // Don't call this.setState() here!

}
showModal=()=>{
    this.setState({show:true});
}
 linkAbout=[
     //  {href:'http://www.micsapp.com',title:'mic'},
       {action:this.showModal,title:"About US"}
      ];
state={
    show:false
}

 hideModal=()=>{
    this.setState({show:false});
}
fs=[
     {"class":"figure col-3 col-lg-1","width":"80px","height":"80px","caption":"Click to Check"}
     ];

submitFunc=()=>{
    alert('submit');
}
  render(){

  
 //procution code will use state
 //<MicsCarousel data={this.state.data1}/>


  return (
    <div className="App">
    <MicsNav dropdown={false} 
    bg={config.bg} ddl={config.ddl} 
    variant={config.variant} 
    homeLink={config.homeLink} 
    homeTitle={config.homeTitle} 
    linkList={config.linkList} 
    linkList2={this.linkAbout}
    logo={config.logo}/>

   <MicsCarousel data={config.data}/>
   <div className="animated bounceInLeft" style={{marginTop:'4em'}}>
   {config.anchor}
   <MicsFigure figures={config.figures}/>
   <hr/>
   <h2 style={{color:'green'}}>Our Customers</h2>
   <MicsFigure figures={config.customers}/>
   </div>
   <MicsModal 
        dialogClassName="modal-dialog"
        show={this.state.show}
        onHide={this.hideModal}
        content={config.aboutUS}
        title={"About US"}
      />
    <hr/>
    <MicsFooter linkList={config.footerLinkList} header={config.footerHeader} brandName="Mic'sApp"/>
    
    </div>
  );
  }
}

export default App;
