import React,{useState} from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Modal } from 'react-bootstrap';
import { Button } from 'react-bootstrap';
import { ButtonToolbar } from 'react-bootstrap';

const  MicsModal = (props)=> {
const [show,setShow]=useState(false);
const b=props.content;
  return (
    <div>
    
    <Modal
      {...props}
      show={props.show}
      size="lg"
      aria-labelledby="blobModal"
      centered
      
    >
      <Modal.Header closeButton>
        <Modal.Title id="title">
          {props.title}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ 'overflow-y': 'auto',wordBreak:'break-all'}}>
   
        {props.content}
       
        <div>
      </div>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide}>Close</Button>
      </Modal.Footer>
    </Modal>
    </div>
  );
}


export default MicsModal;
