import React from "react"
import { Button } from 'react-bootstrap';

const  MicsFooter = (props) =>{
    let ll=props.linkList.map((l)=>{
        return   <a href={l.href} onClick={l.action} style={{padding:'1em'}}><u>{l.title} </u></a>
    })
    return(
        <div className="container text-center" style={{padding:'1em'}}>
        {props.header}
        {ll}
        
        <h4 style={{marginTop:'1em',marginBottom:'2em'}}> {props.brandName} &copy; {new Date().getFullYear()}</h4>
          
        </div>
    
        )
    
}
export default MicsFooter