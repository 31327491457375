import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import {Carousel} from 'react-bootstrap';
import './style.css';
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';
import * as formConfig from '../data/formConfig';
import MDBForm from '../components/MDBForm';

const MicsCarousel =props =>{
  const data=props.data?props.data:[];
  return (
    <div className={props.className}>
  <Carousel interval={!props.interval?0:props.interval}>
  {data.map(
    (item)=>
    <Carousel.Item>
       <img
      className={item.class}
      src={item.src}
      alt={item.alt}
      />
      
      <Carousel.Caption className='mcaption'>
      {ReactHtmlParser(item.caption)}
      <div style={{marginTop:'3em'}}>
      <MDBForm  submitFunc={formConfig.formSubmitFunc} formTitle={formConfig.formTitle} style={formConfig.noUpperCase} buttons={formConfig.formButton}/>
      </div>
      </Carousel.Caption>
    </Carousel.Item>
    
  )}
</Carousel>
</div>
);
}
export default MicsCarousel;