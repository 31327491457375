import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import MicsDropdown from './MicsDropdown';
import MicsNavLink from './MicsNavLink';

const MicsNav =props =>{
  let style={height:50};
//  <Navbar.Brand href={props.homeLink}>{props.homeTitle}</Navbar.Brand>

  let dd=null;
  let ddl=props.ddl;
  if (props.dropdown)  dd=<MicsDropdown ddl={ddl}/>;
  let navLink1=<MicsNavLink dropDown={dd} linkList={props.linkList} class='mr-auto'/>;
  let navLink2=<MicsNavLink linkList={props.linkList2}/>;
  
  return (
  <Navbar collapseOnSelect expand="lg" bg={props.bg} variant={props.variant}>
  <img src={props.logo} style={style}/>
  <Navbar.Toggle aria-controls="responsive-navbar-nav"/>
  <Navbar.Collapse id="responsive-navbar-nav">
    {navLink1}
    {navLink2}
  </Navbar.Collapse>
</Navbar>
);
}
export default MicsNav;                   